@import './shared/index.less';
@zhNavLogoHeight: 34px;
@zhNavLogoWidthPay: 180px;
@zhNavLogoWidth: 128px;
@enNavLogoHeight: 34px;
@enNavLogoWidth: 128px;
@enNavLogoWidthPay: 180px;

.nav-logo {
  float: left;

  img {
    width: 100%;
    vertical-align: top;
  }
}

.en-nav-logo {
  width: @enNavLogoWidth;
  height: @enNavLogoHeight;
  margin: (54px - @enNavLogoHeight) / 2 (189px - @enNavLogoWidth) (54px - @enNavLogoHeight) / 2 0;
}

.zh-nav-logo {
  width: @zhNavLogoWidth;
  height: @zhNavLogoHeight;
  margin: (54px - @zhNavLogoHeight) / 2 (189px - @zhNavLogoWidth) (54px - @zhNavLogoHeight) / 2 0;
}

.zh-nav-logo-pay {
  img {
    width: @zhNavLogoWidth;
    margin-right: 6px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #E3B016;
    position: absolute;
    right: -4px;
    top: -11px;
  }
  width: @zhNavLogoWidthPay;
  height: @zhNavLogoHeight;
  margin: (54px - @zhNavLogoHeight) / 2 (189px - @zhNavLogoWidthPay) (54px - @zhNavLogoHeight) / 2 0;
}

.en-nav-logo-pay {
  img {
    width: @enNavLogoWidth;
    margin-right: 6px;
  }
  span {
    font-weight: 400;
    font-size: 14px;
    color: #E3B016;
    position: absolute;
    right: 10px;
    top: -11px;
  }
  width: @enNavLogoWidthPay;
  height: @enNavLogoHeight;
  margin: (54px - @enNavLogoHeight) / 2 (189px - @enNavLogoWidthPay) (54px - @enNavLogoHeight) / 2 0;
}

.mur-layout{
  box-sizing: border-box;
  padding-top: @layout-header-height;
  min-height: 100vh;
}

.mur-right-layout {
  min-height: calc(100vh - @layout-header-height);
  background: #F5F6F9;
}

.mur-layout-content {
  padding: 24px 24px 0;
  transition: all 0.2s;
  position: relative;
  min-height: auto;
}

.mur-layout-sider {
  overflow: auto;
  position: fixed;
  top: @layout-header-height;
  height: calc(100vh - @layout-header-height);
  box-shadow: 0 4px 12px 2px rgba(0,0,0,0.04);

  .ant-layout-sider-trigger {
    text-align: left;
    padding-left: 30px;
  }
}

.nav-right-action {
  display: flex;
  align-items: center;
  position: absolute;
  right: 50px;
  top: 0;
  color: #f5f5f5;
  z-index: 10;

  .ant-divider {
    height: 1.5em;
    border-color: fade(#f5f5f5, 60%);
    margin: 0 16px;
  }
}

.nav-switch-lang {
  cursor: pointer;
  span {
    margin-right: 4px;
  }
}

.nav-avatar {
  cursor: pointer;
  .nav-avatar-name {
    margin-left: 15px;
    margin-right: 8px;
  }
}

.nav-avatar-pay {
  cursor: pointer;
  .ant-avatar {
    border: 2px solid #d0a860;
    box-shadow: 0px 0px 5px 1px #d0a860;
  }
  .nav-avatar-name-pay {
    margin-left: 15px;
    margin-right: 8px;
    color: #E3B016 !important;
  }
}

//滚动条整体部分
::-webkit-scrollbar {
  width: 8px;
}

// 外层轨道
::-webkit-scrollbar-track {
  background: #fafafa;
}

//滚动条里面可以拖动的部分
::-webkit-scrollbar-thumb {
  background: #d9d9d9;

  &:hover {
    background: #bfbfbf;
  }
}

.mur-admin-container {
  padding: @padding-lg;
  background-color: #fff;
  overflow: hidden;
}

.mur-tag {
  margin-right: 8px;
  padding: 1px 8px;
  color: rgba(43, 64, 150, 1);
  line-height: 22px;
  background-color: rgba(43, 64, 150, 0.08);
  border: 1px solid transparent;
  border-radius: 2px;
  cursor: pointer;

  &:hover {
    color: #ffffff;
    background-color: @primary-color;
    border: 1px solid rgba(43, 64, 150, 0.4);
  }
}

.mur-warning {
  color: @highlight-color;
}

.mur-container {
  background-color: #ffffff;
  box-shadow: 0 0 5px 2px rgba(0,0,0,0.04);
  min-height: 100%;
}

.mur-form-section {
  border-bottom: 1px solid @border-color-split;
  padding: @padding-lg 30px;
  &:last-of-type {
    border: none;
  }
  .ant-upload-list-item-card-actions-btn.ant-btn-sm {
    vertical-align: middle;
    .anticon-close {
      top: 4px;
      right: 2px;
    }
  }
  .ant-progress-status-success .ant-progress-text {
    color: #495ea3;
  }
}
