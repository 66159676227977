// 调试时候引入，打包需要注释
// @import "antd-default.dev";

.ant-menu-horizontal {
  border-bottom: 0;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item {
  margin: 0 20px;
}

.ant-layout-header .ant-menu {
  line-height: @layout-header-height - 6px;
  vertical-align: middle;
  background: transparent;
}

.mur-main-layout {
  .ant-layout-sider-children {
    overflow: auto;
  }

  .ant-menu-sub.ant-menu-inline > .ant-menu-item,
  .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
  .ant-menu-vertical .ant-menu-submenu, .ant-menu-vertical-left .ant-menu-submenu, .ant-menu-vertical-right .ant-menu-submenu, .ant-menu-inline .ant-menu-submenu {
    margin: 0;
  }

  .ant-menu-inline .ant-menu-selected::after, .ant-menu-inline .ant-menu-item-selected::after {
    opacity: 0;
  }
}

.ant-menu-vertical .ant-menu-submenu-selected,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background: rgba(43,64,150,0.08);
  margin: 0;
}

.ant-menu-sub.ant-menu-inline > .ant-menu-item, .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title,
.mur-main-layout .ant-menu-inline > .ant-menu-submenu > .ant-menu-submenu-title {
  height: 48px;
  line-height: 48px;
}

.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title {
  padding: 0;
  text-align: center;
}
.ant-menu-horizontal > .ant-menu-item,
.ant-menu-horizontal > .ant-menu-submenu {
  vertical-align: middle;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: auto;
}

.ant-layout-sider-trigger {
  border-top: 1px solid #E3E3E3;
}

.ant-breadcrumb {
  line-height: 22px;
}

.ant-breadcrumb > span:last-child {
  color: rgba(0, 0, 0, 0.65);
}

.ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
  color: #A8071A;
}

.ant-result-icon {
  margin-bottom: 30px;
}

.ant-result-icon > .anticon {
  font-size: 70px;
}

.ant-result-title {
  line-height: 32px;
  margin-bottom: 8px;
}

.ant-modal-confirm-body > .anticon {
  font-size: 24px;
}

.ant-modal-confirm-body .ant-modal-confirm-title {
  line-height: 24px;
  font-weight: 600;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  margin-top: 12px;
  color: rgba(0, 0, 0, 0.65);
}

.ant-back-top {
  bottom: 80px;
  right: 40px;
}

.ant-table {
  color: rgba(0, 0, 0, 0.65);
}

.ant-table-pagination.ant-pagination .ant-pagination-item-active a,
.ant-pagination-item-active a {
  color: #ffffff !important;
  background: @primary-color;
}

.ant-message {
  z-index: 1000000003;
}

.ant-pagination-item-active {
  background: @primary-color;
}
